import { getTitleAndEmoji } from '@confluence/emoji-title/entry-points/helpers';
import {
	getEmojiDraftFromProperties,
	getEmojiPublishedFromProperties,
} from '@confluence/emoji-title/entry-points/transformer';
import { getURLBypassingResumeDraftAction } from '@confluence/content-utils';

import type { GlobalPageCard, SpacePageCard, PageCardBase } from './card-types';

const ALL_CONTENT_TYPE = ['page', 'blogpost', 'whiteboard', 'database', 'embed', 'folder'];

const resumeDraftWebUiBypass = (urlPath, content, isSpaceAliasFFEnabled): string => {
	const bypassUrl = getURLBypassingResumeDraftAction({
		url: urlPath || '',
		spaceKey:
			(isSpaceAliasFFEnabled && content?.space?.alias
				? content?.space?.alias
				: content?.space?.key) || '',
		contentType: content?.type,
		editorVersion: content?.editorVersion?.nodes?.[0]?.value?.replace(/['"]+/g, ''),
		contentId: content?.id || '',
		noContextPath: true,
	});

	return bypassUrl;
};

export const transformBaseContent = (
	content,
	untitledDraftText?,
	isWhiteboardEmojiFGEnabled?,
	isSpaceAliasFFEnabled?,
): PageCardBase => {
	const EMOJI_CONTENT_TYPE = isWhiteboardEmojiFGEnabled
		? ['page', 'blogpost', 'embed', 'whiteboard']
		: ['page', 'blogpost', 'embed'];
	const contentType = ALL_CONTENT_TYPE.includes(content?.type) ? content.type : 'page';
	return {
		id: content?.id || '',
		status: content?.status,
		createdDate: new Date(content?.metadata?.createdDate),
		// @ts-expect-error TODO FIXME there is some deep null mismatch, which might be worth fixing
		title: getTitleAndEmoji(
			content?.title,
			getEmojiPublishedFromProperties(content),
			getEmojiDraftFromProperties(content),
			content?.status,
			untitledDraftText,
			EMOJI_CONTENT_TYPE.includes(contentType),
		),
		type: contentType,
		subType: content?.subType,
		editorVersion: content?.editorVersion,
		links: {
			editui: resumeDraftWebUiBypass(content?.links?.editui, content, isSpaceAliasFFEnabled),
			webui: resumeDraftWebUiBypass(content?.links?.webui, content, isSpaceAliasFFEnabled),
		},
		isStarred: content?.metadata?.currentuser?.favourited?.isFavourite || false,
		likesCount: content?.likes?.count || 0,
		commentsCount: content?.metadata?.comments?.commentsCount || 0,
		excerpt: content?.excerpt?.trim(),
		lastAction: {
			when: content?.metadata?.lastModifiedDate || '',
			action: 'updated',
		},
	};
};

export const transformGlobalContent = (
	content,
	untitledDraftText?,
	isSpaceAliasFFEnabled?,
	isWhiteboardEmojiFGEnabled = false,
): GlobalPageCard => {
	const spaceAlias =
		isSpaceAliasFFEnabled && !!content?.space?.alias ? content?.space?.alias : content?.space?.key;
	return {
		...transformBaseContent(content, untitledDraftText, isWhiteboardEmojiFGEnabled),
		space: {
			spaceKey: spaceAlias || '',
			name: content?.space?.name || '',
		},
	};
};

export const transformSpaceContent = (
	content,
	untitledDraftText: string = '',
	isWhiteboardEmojiFGEnabled = false,
	isSpaceAliasFFEnabled?,
): SpacePageCard => {
	const createdBy =
		content?.status === 'draft' ? content?.version?.by : content?.history?.createdBy;
	const ownedBy = content?.status === 'draft' ? content?.version?.by : content?.history?.ownedBy;
	return {
		...transformBaseContent(
			content,
			untitledDraftText,
			isWhiteboardEmojiFGEnabled,
			isSpaceAliasFFEnabled,
		),
		createdBy: {
			displayName: createdBy?.displayName || '',
			profilePicture: createdBy?.profilePicture?.path || '',
			accountId: createdBy?.accountId || '',
		},
		ownedBy: {
			displayName: ownedBy?.displayName || '',
			profilePicture: ownedBy?.profilePicture?.path || '',
			accountId: ownedBy?.accountId || '',
		},
	};
};

export const isSpacePageCard = (card: PageCardBase): card is SpacePageCard =>
	Boolean((card as SpacePageCard).createdBy);
